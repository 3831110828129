import React from "react"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  map: {
    width: "100%",
    zIndex: 9,

    [theme.breakpoints.down("sm")]: {
      height: "calc(100vh - 150px)",
    },
    [theme.breakpoints.up("md")]: {
      height: "calc(100vh - 270px)",
    },
  },
}))

const TourMap = ({ tour }) => {
  const classes = useStyles()
  const bounds = []
  const polygon = []

  tour.data.services.forEach(service => {
    bounds.push([
      service.service_link.document.data.location.latitude,
      service.service_link.document.data.location.longitude,
    ])

    polygon.push([
      service.service_link.document.data.location.latitude,
      service.service_link.document.data.location.longitude,
    ])
  })

  return <></>
}

export default TourMap

TourMap.propTypes = {
  tour: PropTypes.object.isRequired,
}
