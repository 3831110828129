/* eslint-disable react/require-default-props */
import React, { useState } from "react"
import PropTypes from "prop-types"
import AnchorLink from "react-anchor-link-smooth-scroll"

import { graphql } from "gatsby"
import Img from "gatsby-image"
import { makeStyles } from "@material-ui/core/styles"
import {
  BottomNavigation,
  BottomNavigationAction,
  Container,
  Grid,
  Link as MaterialLink,
  Typography,
} from "@material-ui/core"

import StoreIcon from "@material-ui/icons/Store"
import PhoneIcon from "@material-ui/icons/Phone"

import { LocaleContext } from "../contexts/LocaleContext"
import Breadcrumbs from "../components/Breadcrumbs"
import ImageSlider from "../components/ImageSlider"
import Facilities from "../components/tours/Facilities"
import Itinerary from "../components/tours/Itinerary"
import TourMap from "../components/maps/TourMap"
import TourBookForm from "../components/tours/TourBookForm"
import NeedHelpBlock from "../components/NeedHelpBlock"
import SEO from "../components/SEO"

const useStyles = makeStyles(theme => ({
  topWrapper: {
    position: "relative",

    [theme.breakpoints.down("sm")]: {
      height: "calc(100vh - 60px)",
    },
    [theme.breakpoints.up("md")]: {
      height: "calc(100vh - 160px)",
    },
  },
  mainImage: {
    height: "100%",
  },
  topOverlay: {
    position: "absolute",
    width: "100%",
    backgroundColor: "rgba(0,0,0,.4)",
    bottom: 0,
    left: 0,
  },

  topOverlayContainer: {
    height: "100%",
  },
  titleWrapper: {
    height: "100%",
  },
  title: {
    fontSize: 36,
    textTransform: "uppercase",
    fontWeight: 700,
    color: theme.palette.common.white,
  },
  priceDetails: {
    color: theme.palette.common.white,
  },
  price: {
    color: theme.palette.turistBlue.main,
    fontSize: 60,
  },

  contentWrapper: {
    marginTop: theme.spacing(2),
  },

  bookNowContainer: {
    zIndex: 9999,
    background: theme.palette.common.white,
    position: "sticky",
    top: "calc(100vh - 54px)",
  },

  contents: {
    "& li": {
      fontSize: 20,
      listStyleType: "upper-roman",
    },
  },

  bottomNav: {
    width: "100%",
    zIndex: 999,
    position: "fixed",
    bottom: 0,
    background: theme.palette.primary.main,
  },

  bottomNavAction: {
    color: theme.palette.common.white,
  },
}))

const Tour = ({
  data: { tour },
  pageContext: { locale, slug, breadcrumbs, alternateLanguages },
  location,
}) => {
  const classes = useStyles()
  const lang = React.useContext(LocaleContext)
  const i18n = lang.i18n[lang.locale]

  const getAnchorOffset = () => {
    return 20
  }

  const [bookFormOpen, setBookFormOpen] = useState(false)

  const handleShowBookForm = () => {
    setBookFormOpen(true)
  }

  const handleCloseBookForm = () => {
    setBookFormOpen(false)
  }

  const [lastBreadcrumb] = breadcrumbs.slice(-1)
  const { path } = lastBreadcrumb
  const canonical = `${process.env.GATSBY_BASE_URL}${path}`

  const meta = {
    title: tour.data.meta_title,
    description: tour.data.meta_description,
    keywords: tour.data.meta_keywords,
    author: "Turist in Transilvania",
  }

  const schema = tour.data.schema_json.text || ""
  const ogp = tour.data.ogp_tags || []

  ogp.push({
    property: "og:image",
    content: tour.data.main_image.fluid.src,
  })
  ogp.push({
    property: "og:image:alt",
    content: tour.data.main_image.alt,
  })
  ogp.push({
    property: "og:image:width",
    content: tour.data.main_image.dimensions.width,
  })
  ogp.push({
    property: "og:image:height",
    content: tour.data.main_image.dimensions.height,
  })
  ogp.push({
    property: "og:image:type",
    content: "image/jpeg",
  })

  const parsedSchema = JSON.parse(schema)

  parsedSchema["@graph"].push({
    "@type": "Product",
    "@context": "https://schema.org/",
    productID: tour.uid,
    sku: tour.uid,
    image: tour.data.main_image.fluid.src,
    url: canonical,
    name: tour.data.title.text,
    description: tour.data.introduction.text,
    brand: {
      "@type": "Thing",
      "@id": null,
      name: "Turist in Transilvania",
    },
    aggregateRating: {
      "@type": "AggregateRating",
      ratingValue: 4.5,
      worstRating: 1.0,
      bestRating: 5.0,
      reviewCount: 1156,
    },
    offers: null,
  })

  parsedSchema["@graph"].push({
    "@type": "TouristTrip",
    "@context": "http://schema.org",
    offers: {
      "@type": "Offer",
      name: i18n.generalOfferName,
      description: i18n.generalOfferDescription,
      price: tour.data.prices[0].amount,
      priceCurrency: tour.data.prices[0].currency,
      availabilityEnds: "2030-10-31",
      url: canonical,
      offeredBy: {
        "@type": "Organization",
        name: "Turist in Transilvania",
        url: `${process.env.GATSBY_BASE_URL}/${i18n.path}/`,
      },
    },
    name: tour.data.title.text,
    description: tour.data.introduction.text,
    touristType: [tour.data.category.document.data.title.text],
    itinerary: {
      "@type": "ItemList",
      numberOfItems: tour.data.services.length,
      itemListElement: tour.data.services.map((service, index) => ({
        "@type": "ListItem",
        position: index + 1,
        item: {
          "@type": "TouristAttraction",
          name: service.service_link.document.data.title.text,
          description: service.service_description.text,
        },
      })),
    },
  })

  const imageSlides = tour.data.slider_images.map(({ slider_image }) => ({
    alt: slider_image.alt,
    fluid: slider_image.fluid,
  }))

  return (
    <>
      <SEO
        schema={JSON.stringify(parsedSchema)}
        ogp={ogp}
        meta={meta}
        locale={locale}
        breadcrumbs={breadcrumbs}
        location={location}
        alternateLanguages={alternateLanguages}
      />
      <div className={classes.topWrapper}>
        <Img
          alt={tour.data.main_image.alt}
          className={classes.mainImage}
          fluid={tour.data.main_image.fluid}
        />
        <div className={classes.topOverlay}>
          <Container maxWidth="lg" className={classes.topOverlayContainer}>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
              className={classes.titleWrapper}
            >
              <Grid item xs={12} md={9}>
                <Typography component="h1" className={classes.title}>
                  {tour.data.title.text}
                </Typography>
              </Grid>
              <Grid item xs={12} md={3}>
                <Typography component="span" className={classes.priceDetails}>
                  {i18n.from}
                </Typography>
                <Typography component="span" className={classes.price}>
                  {tour.data.prices[0].amount}&nbsp;
                  {tour.data.prices[0].currency}
                </Typography>
              </Grid>
            </Grid>
          </Container>
        </div>
      </div>
      <Breadcrumbs breadcrumbs={breadcrumbs} />

      <Container maxWidth="lg" className={classes.contentWrapper}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography component="h2" variant="h3">
              {i18n.summary}
            </Typography>
            <ul className={classes.contents}>
              <li>
                <MaterialLink
                  component={AnchorLink}
                  offset={getAnchorOffset()}
                  href="#about"
                >
                  {i18n.tourAbout}
                </MaterialLink>
              </li>
              <li>
                <MaterialLink
                  component={AnchorLink}
                  offset={getAnchorOffset()}
                  href="#itinerary"
                >
                  {i18n.tourItinerary}
                </MaterialLink>
              </li>
              <li>
                <MaterialLink
                  component={AnchorLink}
                  offset={getAnchorOffset()}
                  href="#gallery"
                >
                  {i18n.tourGallery}
                </MaterialLink>
              </li>
              <li>
                <MaterialLink
                  component={AnchorLink}
                  offset={getAnchorOffset()}
                  href="#facilities"
                >
                  {i18n.tourFacilities}
                </MaterialLink>
              </li>
              <li>
                <MaterialLink
                  component={AnchorLink}
                  offset={getAnchorOffset()}
                  href="#map"
                >
                  {i18n.tourMap}
                </MaterialLink>
              </li>
            </ul>
          </Grid>
        </Grid>
      </Container>

      <BottomNavigation showLabels className={classes.bottomNav}>
        <BottomNavigationAction
          className={classes.bottomNavAction}
          label={i18n.requestBooking}
          icon={<StoreIcon />}
          onClick={handleShowBookForm}
        />
        <BottomNavigationAction
          component="a"
          href={`tel:${i18n.phone}`}
          className={classes.bottomNavAction}
          label={i18n.callForDetails}
          icon={<PhoneIcon />}
        />
      </BottomNavigation>

      <Container maxWidth="lg" className={classes.contentWrapper}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography component="h2" variant="h3" id="about">
              {i18n.tourAbout}
            </Typography>
            <br />
            <Typography
              variant="body1"
              dangerouslySetInnerHTML={{
                __html: tour.data.description.html,
              }}
            />
            <br />
            <br />
            <br />

            <Typography component="h2" variant="h3" id="itinerary">
              {i18n.tourItinerary}
            </Typography>
            <br />
            <Itinerary services={tour.data.services} />
            <br />
            <br />
            <br />

            <Typography component="h2" variant="h3" id="gallery">
              {i18n.tourGallery}
            </Typography>
            <br />
            <ImageSlider slides={imageSlides} />
            <br />
            <br />
            <br />

            <Typography component="h2" variant="h3" id="facilities">
              {i18n.tourFacilities}
            </Typography>
            <br />
            <Facilities tour={tour} />
            <br />
            <br />
            <br />

            <Typography component="h2" variant="h3" id="map">
              {i18n.tourMap}
            </Typography>
            <br />
            <TourMap tour={tour} />
            <br />
            <br />
            <br />
            <NeedHelpBlock />
          </Grid>
        </Grid>
      </Container>

      <TourBookForm
        tour={tour}
        open={bookFormOpen}
        handleClose={() => handleCloseBookForm()}
      />
    </>
  )
}

export default Tour

export const query = graphql`
  query TourQuery($slug: String!, $locale: String!) {
    tour: prismicTour(uid: { eq: $slug }, lang: { eq: $locale }) {
      uid
      lang
      data {
        ogp_tags {
          content
          property
        }
        schema_json {
          text
        }
        meta_title
        meta_description
        meta_keywords
        title {
          text
        }
        description {
          html
        }
        introduction {
          text
        }
        category {
          document {
            ... on PrismicTourCategory {
              data {
                icon
                title {
                  text
                }
              }
            }
          }
        }
        wine_tasting
        transportation
        traditional_lunch
        tour_guide
        pax_min
        pax_max
        duration
        introduction {
          html
        }
        description {
          html
        }
        prices {
          amount
          currency
        }
        tour_guide
        traditional_lunch
        transportation
        wine_tasting
        duration
        booking_system_id
        services {
          service_duration
          service_description {
            text
          }
          service_link {
            document {
              ... on PrismicService {
                id
                data {
                  address
                  location {
                    latitude
                    longitude
                  }
                  title {
                    text
                  }
                  main_image {
                    alt
                    fixed(width: 100) {
                      ...GatsbyPrismicImageFixed
                    }
                  }
                }
              }
            }
          }
        }
        main_image {
          alt
          dimensions {
            height
            width
          }
          fluid(maxWidth: 1980) {
            ...GatsbyPrismicImageFluid
          }
        }
        slider_images {
          slider_image {
            alt
            fluid(maxWidth: 1024) {
              ...GatsbyPrismicImageFluid
            }
          }
        }
      }
    }
  }
`

Tour.propTypes = {
  data: PropTypes.shape({
    tour: PropTypes.object.isRequired,
  }).isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    breadcrumbs: PropTypes.array.isRequired,
    alternateLanguages: PropTypes.array.isRequired,
  }).isRequired,
  location: PropTypes.object.isRequired,
  width: PropTypes.string.isRequired,
}
