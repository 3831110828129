import React, { useState } from "react"

import PropTypes from "prop-types"

import axios from "axios"
import add from "date-fns/add"
import format from "date-fns/format"
import startOfDay from "date-fns/startOfDay"
import isAfter from "date-fns/isAfter"
import parseISO from "date-fns/parseISO"
import { ro, de, enGB } from "date-fns/locale"

import { makeStyles } from "@material-ui/core/styles"
import {
  AppBar,
  Button,
  Dialog,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Paper,
  Slide,
  Snackbar,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  TextField,
  Toolbar,
  Typography,
} from "@material-ui/core"

import { Alert } from "@material-ui/lab"

import CloseIcon from "@material-ui/icons/Close"

import { LocaleContext } from "../../contexts/LocaleContext"

const useStyles = makeStyles(theme => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },

  stepper: {
    width: "100%",
  },
  stepLabel: {
    color: theme.palette.common.black,
    fontSize: 20,
  },

  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },

  inputLabel: {
    color: "initial",
  },

  priceSummary: {
    maxWidth: 400,
  },
  infoPrice: {
    fontSize: 14,
    fontWeight: 700,
    textAlign: "right",
  },

  totalLabel: {
    fontWeight: 700,
    fontSize: 20,
  },

  totalPrice: {
    fontWeight: 700,
    fontSize: 20,
  },
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const TourBookForm = ({ tour, open, handleClose }) => {
  const classes = useStyles()
  const lang = React.useContext(LocaleContext)
  const i18n = lang.i18n[lang.locale]

  const [valid, setValid] = useState({
    date: true,
    pax: true,
    firstName: false,
    lastName: false,
    email: false,
    phone: false,
  })

  const date = add(new Date(), { days: 1 })
  const [values, setValues] = useState({
    date: format(date, "yyyy-MM-dd"),
    pax: 1,
  })

  const [customer, setCustomer] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
  })

  const [activeStep, setActiveStep] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [calculatedPrice, setCalculatedPrice] = useState(false)
  const [showSnackbar, setShowSnackbar] = useState(false)

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1)
  }

  const handleCloseSnackbar = () => {
    setShowSnackbar(false)
  }

  const handleCustomerChange = e => {
    const { name, value } = e.target

    if (["firstName", "lastName", "email", "phone"].includes(name)) {
      if (value) {
        valid[name] = true
        setValid({ ...valid })
      } else {
        valid[name] = false
        setValid({ ...valid })
      }
    }

    customer[name] = value
    setCustomer({ ...customer })
  }

  const handleChangePax = e => {
    const { value } = e.target

    if (value >= 1) {
      valid.pax = true
      values.pax = value
    }

    setValues({ ...values })
  }

  const handleChangeDate = e => {
    const { value } = e.target

    if (isAfter(parseISO(value), startOfDay(new Date()))) {
      valid.date = true
      values.date = value
    }

    setValues({ ...values })
  }

  const handleCalculatePrice = () => {
    calculatePrice()
  }

  const handleBookTour = () => {
    bookTour()
  }

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }

  const calculatePrice = () => {
    setIsLoading(true)

    const axiosOptions = {
      url: `${process.env.GATSBY_TOURIST_BACKEND_API_URL}/tour/${tour.data.booking_system_id}/price`,
      method: "get",
      headers: { "Content-Type": "application/json" },
      params: {
        pax: parseInt(values.pax, 10),
        date: values.date,
      },
    }

    axios(axiosOptions)
      .then(response => {
        setIsLoading(false)
        setCalculatedPrice(response.data)
        handleNext()
      })
      .catch(err => {
        console.log(err)
        setIsLoading(false)
        setShowSnackbar(true)
      })
  }

  const bookTour = () => {
    setIsLoading(true)
    const axiosOptions = {
      url: `${process.env.GATSBY_TOURIST_BACKEND_API_URL}/tour/${tour.data.booking_system_id}/book`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: {
        pax: values.pax,
        date: values.date,
        firstName: customer.firstName,
        lastName: customer.lastName,
        email: customer.email,
        phone: customer.phone,
        type: "individual",
      },
    }

    axios(axiosOptions)
      .then(response => {
        setIsLoading(false)
        handleNext()
      })
      .catch(err => {
        console.log(err)
        setIsLoading(false)
        setShowSnackbar(true)
      })
  }

  return (
    <>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {i18n.requestBooking}
            </Typography>
          </Toolbar>
        </AppBar>
        <div className={classes.stepper}>
          <form className={classes.container} noValidate>
            <Stepper activeStep={activeStep} orientation="vertical">
              <Step>
                <StepLabel
                  classes={{
                    label: classes.stepLabel,
                  }}
                >
                  {i18n.calculatePrice}
                </StepLabel>
                <StepContent>
                  <Typography>{i18n.calculatePriceDescription}</Typography>
                  <br />
                  <br />

                  <Grid
                    container
                    spacing={3}
                    direction="column"
                    alignItems="stretch"
                  >
                    <Grid item>
                      <TextField
                        name="date"
                        variant="outlined"
                        fullWidth
                        id="date"
                        label={i18n.date}
                        value={values.date}
                        onChange={handleChangeDate}
                        type="date"
                        className={classes.textField}
                        inputProps={{
                          min: format(new Date(), "yyyy-MM-dd"),
                        }}
                        InputLabelProps={{
                          shrink: true,
                          color: "primary",
                          className: classes.inputLabel,
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        name="pax"
                        variant="outlined"
                        fullWidth
                        id="pax"
                        label={i18n.persons}
                        value={values.pax}
                        onChange={handleChangePax}
                        type="number"
                        className={classes.textField}
                        inputProps={{
                          min: 1,
                        }}
                        InputLabelProps={{
                          shrink: true,
                          color: "primary",
                          className: classes.inputLabel,
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={handleCalculatePrice}
                        size="large"
                        disabled={isLoading}
                      >
                        {i18n.calculate}
                      </Button>
                    </Grid>
                  </Grid>
                </StepContent>
              </Step>
              <Step>
                <StepLabel
                  classes={{
                    label: classes.stepLabel,
                  }}
                >
                  {i18n.calculatedPrice}
                </StepLabel>
                <StepContent>
                  <Typography>{i18n.calculatedPriceDescription}</Typography>
                  <br />
                  <br />

                  <Grid
                    container
                    spacing={3}
                    justify="center"
                    alignItems="stretch"
                    direction="column"
                  >
                    {calculatedPrice && (
                      <Grid item>
                        <List dense className={classes.priceSummary}>
                          <ListItem>
                            <ListItemText primary={i18n.date} />
                            <ListItemSecondaryAction
                              className={classes.infoPrice}
                            >
                              {`${format(values.date, "ll")} `}
                            </ListItemSecondaryAction>
                          </ListItem>
                          <Divider light />
                          <ListItem>
                            <ListItemText primary={i18n.persons} />
                            <ListItemSecondaryAction
                              className={classes.infoPrice}
                            >
                              {`${values.pax} `}
                            </ListItemSecondaryAction>
                          </ListItem>
                          <Divider light />
                          <ListItem>
                            <ListItemText primary={i18n.pricePerPerson} />
                            <ListItemSecondaryAction
                              className={classes.infoPrice}
                            >
                              {`${
                                Object.values(calculatedPrice.units)[0].ppu
                              } ${calculatedPrice.currency}`}
                            </ListItemSecondaryAction>
                          </ListItem>
                          <Divider light />
                          <Divider light />
                          <Divider light />
                          <ListItem>
                            <ListItemText>
                              <Typography
                                className={classes.totalLabel}
                                component="div"
                              >
                                {i18n.total}
                              </Typography>
                            </ListItemText>
                            <ListItemSecondaryAction
                              className={classes.totalPrice}
                            >
                              {`${calculatedPrice.amount} ${calculatedPrice.currency}`}
                            </ListItemSecondaryAction>
                          </ListItem>
                        </List>
                      </Grid>
                    )}

                    <Grid item>
                      <Button color="primary" onClick={handleBack} size="large">
                        {i18n.back}
                      </Button>
                      &nbsp;
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={handleNext}
                        size="large"
                      >
                        {i18n.continue}
                      </Button>
                    </Grid>
                  </Grid>
                </StepContent>
              </Step>

              <Step>
                <StepLabel
                  classes={{
                    label: classes.stepLabel,
                  }}
                >
                  {i18n.personalData}
                </StepLabel>
                <StepContent>
                  <Typography>{i18n.personalDataDescription}</Typography>
                  <br />
                  <br />

                  <Grid
                    container
                    spacing={3}
                    justify="center"
                    alignItems="stretch"
                    direction="column"
                  >
                    <Grid item>
                      <TextField
                        name="firstName"
                        variant="outlined"
                        fullWidth
                        id="firstName"
                        label={i18n.firstName}
                        error={!valid.firstName}
                        required
                        value={customer.firstName}
                        onChange={handleCustomerChange}
                        type="text"
                        className={classes.textField}
                        InputLabelProps={{
                          shrink: true,
                          color: "primary",
                          className: classes.inputLabel,
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        name="lastName"
                        variant="outlined"
                        fullWidth
                        id="lastName"
                        label={i18n.lastName}
                        required
                        value={customer.lastName}
                        error={!valid.lastName}
                        onChange={handleCustomerChange}
                        type="text"
                        className={classes.textField}
                        InputLabelProps={{
                          shrink: true,
                          color: "primary",
                          className: classes.inputLabel,
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        name="email"
                        variant="outlined"
                        fullWidth
                        id="email"
                        label={i18n.email}
                        required
                        error={!valid.email}
                        value={customer.email}
                        onChange={handleCustomerChange}
                        type="email"
                        className={classes.textField}
                        InputLabelProps={{
                          shrink: true,
                          color: "primary",
                          className: classes.inputLabel,
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        name="phone"
                        variant="outlined"
                        fullWidth
                        id="phone"
                        label={i18n.phoneInput}
                        error={!valid.phone}
                        required
                        value={customer.phone}
                        onChange={handleCustomerChange}
                        type="phone"
                        className={classes.textField}
                        InputLabelProps={{
                          shrink: true,
                          color: "primary",
                          className: classes.inputLabel,
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <Button color="primary" onClick={handleBack} size="large">
                        {i18n.back}
                      </Button>
                      &nbsp;
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={handleNext}
                        size="large"
                      >
                        {i18n.continue}
                      </Button>
                    </Grid>
                  </Grid>
                </StepContent>
              </Step>

              <Step>
                <StepLabel
                  classes={{
                    label: classes.stepLabel,
                  }}
                >
                  {i18n.summaryStep}
                </StepLabel>
                <StepContent>
                  <Typography>{i18n.summaryStepDescription}</Typography>
                  <br />
                  <br />

                  <Grid
                    container
                    spacing={3}
                    justify="center"
                    alignItems="stretch"
                    direction="column"
                  >
                    <Grid item>
                      <List dense className={classes.priceSummary}>
                        <ListItem>
                          <ListItemText primary={i18n.persons} />
                          <ListItemSecondaryAction
                            className={classes.infoPrice}
                          >
                            {values.pax}
                          </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem>
                          <ListItemText primary={i18n.date} />
                          <ListItemSecondaryAction
                            className={classes.infoPrice}
                          >
                            {`${format(parseISO(values.date), "PP", {
                              locale:
                                lang.locale === "de-de"
                                  ? de
                                  : lang.locale === "ro"
                                  ? ro
                                  : enGB,
                            })} `}
                          </ListItemSecondaryAction>
                        </ListItem>
                        <Divider light />
                        <ListItem>
                          <ListItemText primary={i18n.fullName} />
                          <ListItemSecondaryAction
                            className={classes.infoPrice}
                          >
                            {`${customer.firstName} ${customer.lastName}`}
                          </ListItemSecondaryAction>
                        </ListItem>
                        <Divider light />
                        <ListItem>
                          <ListItemText primary={i18n.email} />
                          <ListItemSecondaryAction
                            className={classes.infoPrice}
                          >
                            {`${customer.email}`}
                          </ListItemSecondaryAction>
                        </ListItem>
                        <Divider light />
                        <ListItem>
                          <ListItemText primary={i18n.phoneInput} />
                          <ListItemSecondaryAction
                            className={classes.infoPrice}
                          >
                            {`${customer.phone}`}
                          </ListItemSecondaryAction>
                        </ListItem>

                        <Divider light />
                        <Divider light />
                        <Divider light />
                        <ListItem>
                          <ListItemText>
                            <Typography
                              className={classes.totalLabel}
                              component="div"
                            >
                              {i18n.total}
                            </Typography>
                          </ListItemText>
                          <ListItemSecondaryAction
                            className={classes.totalPrice}
                          >
                            {`${calculatedPrice.amount} ${calculatedPrice.currency}`}
                          </ListItemSecondaryAction>
                        </ListItem>
                      </List>
                    </Grid>
                    <Grid item>
                      <Button color="primary" onClick={handleBack} size="large">
                        {i18n.back}
                      </Button>
                      &nbsp;
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={handleBookTour}
                        size="large"
                        disabled={isLoading}
                      >
                        {i18n.requestBooking}
                      </Button>
                    </Grid>
                  </Grid>
                </StepContent>
              </Step>
            </Stepper>
          </form>
          {activeStep === 4 && (
            <Paper square elevation={0} className={classes.resetContainer}>
              <Alert icon={false}>
                <Typography variant="h4">{i18n.bookingThanks}</Typography>
                <br />
                <Typography>{i18n.bookingTourThanksDescription}</Typography>
              </Alert>
            </Paper>
          )}
        </div>
      </Dialog>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={showSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity="error">
          {i18n.bookingError}
        </Alert>
      </Snackbar>
    </>
  )
}

export default TourBookForm

TourBookForm.propTypes = {
  tour: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
}
