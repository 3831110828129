import React from "react"
import PropTypes from "prop-types"

import { makeStyles } from "@material-ui/core/styles"
import { Avatar, Paper, Typography } from "@material-ui/core"

import { isMobileOnly, withOrientationChange } from "react-device-detect"

import {
  Timeline,
  TimelineItem,
  TimelineContent,
  TimelineOppositeContent,
  TimelineSeparator,
  TimelineDot,
  TimelineConnector,
} from "@material-ui/lab"

const useStyles = makeStyles(theme => ({
  paper: {
    padding: 25,
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },

  title: {
    fontWeight: 700,
    marginBottom: 5,
  },
  timelineItem: {
    marginBottom: theme.spacing(4),
  },

  addressSecondary: {
    marginBottom: 5,
  },

  hideTimelineOppositeContent: {
    display: "none",
  },

  dot: {
    padding: 1,
  },

  dotImage: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
}))

const Itinerary = ({ services, isLandscape, isPortrait }) => {
  const classes = useStyles()

  let alignTimeline = "alternate"

  if (isMobileOnly) {
    alignTimeline = "left"
  }

  return (
    <Timeline align={alignTimeline}>
      {services.map((service, index) => (
        <TimelineItem
          key={service.service_link.document.id}
          className={classes.timelineItem}
        >
          <TimelineOppositeContent
            className={isMobileOnly ? classes.hideTimelineOppositeContent : ""}
          >
            <Typography variant="h4" color="primary">
              {service.service_link.document.data.address}
            </Typography>
          </TimelineOppositeContent>

          <TimelineSeparator>
            <TimelineDot className={classes.dot} color="primary">
              <Avatar
                alt={service.service_link.document.data.main_image.alt}
                src={service.service_link.document.data.main_image.fixed.src}
                className={classes.dotImage}
              />
            </TimelineDot>

            {index + 1 !== services.length && <TimelineConnector />}
          </TimelineSeparator>
          <TimelineContent>
            <Paper elevation={3} className={classes.paper}>
              {isMobileOnly && (
                <Typography
                  variant="h5"
                  color="primary"
                  className={classes.addressSecondary}
                >
                  {service.service_link.document.data.address}
                </Typography>
              )}
              <Typography variant="h6" className={classes.title}>
                {service.service_link.document.data.title.text}
              </Typography>

              <Typography>{service.service_description.text}</Typography>
            </Paper>
          </TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>
  )
}

export default withOrientationChange(Itinerary)

Itinerary.propTypes = {
  services: PropTypes.array.isRequired,
  isLandscape: PropTypes.bool.isRequired,
  isPortrait: PropTypes.bool.isRequired,
}
