import React from "react"
import { uniq } from "lodash"
import PropTypes from "prop-types"

import { makeStyles } from "@material-ui/core/styles"
import { Card, CardContent, Grid, Icon, Typography } from "@material-ui/core"

import { LocaleContext } from "../../contexts/LocaleContext"

const useStyles = makeStyles(theme => ({
  summaryBox: {
    fontSize: 14,
  },
  summaryOutline: {
    padding: "2px 10px",
    lineHeight: "30px",
  },
}))

const Facilities = ({ tour }) => {
  const classes = useStyles()
  const lang = React.useContext(LocaleContext)
  const i18n = lang.i18n[lang.locale]

  const getLocations = t => {
    const locations = t.data.services.map(
      service => service.service_link.document.data.address
    )

    return uniq(locations).join(", ")
  }

  return (
    <Grid container spacing={2}>
      <Grid item className={classes.summaryBox} xs={12} sm={6} md={3}>
        <Card className={classes.root} variant="outlined">
          <CardContent>
            <Typography variant="h5" component="div">
              <Icon className="icon_set_1_icon-83" />
              &nbsp;{tour.data.duration / 60} {i18n.hours}
            </Typography>
            <br />
            <Typography variant="body2" component="p">
              {i18n.tourDurationDescription}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item className={classes.summaryBox} xs={12} sm={6} md={3}>
        <Card className={classes.root} variant="outlined">
          <CardContent>
            <Typography variant="h5" component="div">
              <Icon className="icon_set_1_icon-30" />
              &nbsp;{tour.data.services.length} {i18n.activities}
            </Typography>
            <br />
            <Typography variant="body2" component="p">
              {i18n.tourActivitiesDescription}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      {tour.data.traditional_lunch && (
        <Grid item className={classes.summaryBox} xs={12} sm={6} md={3}>
          <Card className={classes.root} variant="outlined">
            <CardContent>
              <Typography variant="h5" component="div">
                <Icon className="icon_set_1_icon-58" />
                &nbsp;{i18n.traditionalLunch}
              </Typography>
              <br />
              <Typography variant="body2" component="p">
                {i18n.tourTraditionalLunchDescription}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      )}

      {tour.data.tour_guide && (
        <Grid item className={classes.summaryBox} xs={12} sm={6} md={3}>
          <Card className={classes.root} variant="outlined">
            <CardContent>
              <Typography variant="h5" component="div">
                <Icon className="icon_set_1_icon-29" style={{ fontSize: 22 }} />
                &nbsp;{i18n.tourGuide}
              </Typography>
              <br />
              <Typography variant="body2" component="p">
                {i18n.tourTourGuideDescription}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      )}

      {tour.data.wine_tasting && (
        <Grid item className={classes.summaryBox} xs={12} sm={6} md={3}>
          <Card className={classes.root} variant="outlined">
            <CardContent>
              <Typography variant="h5" component="div">
                <Icon className="icon_set_1_icon-15" style={{ fontSize: 22 }} />
                &nbsp;{i18n.wineTasting}
              </Typography>
              <br />
              <Typography variant="body2" component="p">
                {i18n.tourWineTastingDescription}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      )}

      {tour.data.transportation && (
        <Grid item className={classes.summaryBox} xs={12} sm={6} md={3}>
          <Card className={classes.root} variant="outlined">
            <CardContent>
              <Typography variant="h5" component="div">
                <Icon className="icon_set_1_icon-25" style={{ fontSize: 22 }} />
                &nbsp;{i18n.includedTransportation}
              </Typography>
              <br />
              <Typography variant="body2" component="p">
                {i18n.tourTransportationDescription}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      )}

      <Grid item className={classes.summaryBox} xs={12} sm={6} md={3}>
        <Card className={classes.root} variant="outlined">
          <CardContent>
            <Typography variant="h5" component="div">
              <Icon className="icon_set_1_icon-41" style={{ fontSize: 22 }} />
              &nbsp;{getLocations(tour)}
            </Typography>
            <br />
            <Typography variant="body2" component="p">
              {i18n.tourLocationsDescription}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}

export default Facilities

Facilities.propTypes = {
  tour: PropTypes.object.isRequired,
}
